<template>
  <div class="footerTab">
    <div class="number">选取{{ multipleSelection.length }}项</div>
    <div class="right">
      <div class="cancle" @click="hidden">取消</div>
      <el-button type="danger" @click="del">删除</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterTab",
  props: {
    multipleSelection: {
      type: Array,
      default: () => [],
    },
    message: {
      type: String,
      default: "此操作将永久删除该信息, 是否继续?",
    },
    text: {
      type: String,
      default: "请选择需要删除的教师账号",
    },
  },
  data() {
    return {};
  },
  methods: {
    hidden() {
      this.$emit("hidden");
    },
    del() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: "info",
          message: this.text,
        });
      } else {
        this.$confirm(this.message, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$emit("delete");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.footerTab {
  width: 100%;
  height: 60px;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  bottom: 0;
  left: 0;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 360px;
  .right {
    display: flex;
    align-items: center;
    .cancle {
      margin-right: 24px;
      cursor: pointer;
    }
  }
}
</style>
