<template>
  <div class="SchoolLeadership">
    <div class="header">
      <div>
        <el-input
          v-model="name"
          class="search-input"
          placeholder="搜索老师名称"
          @keyup.enter.native="searchList"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="searchList"
          >
          </i>
        </el-input>
      </div>
      <div>
        <el-button type="primary" plain @click="showImport">批量导入</el-button>
        <el-button type="primary" plain @click="addTeacher">添加</el-button>
        <el-button type="success" plain>导出</el-button>
        <el-button type="danger" plain>清空</el-button>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="teacherList"
      tooltip-effect="dark"
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="序号" width="120" type="index" align="center">
      </el-table-column>
      <el-table-column prop="name" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="address" label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <addTeacherModel
      ref="addTeacherModel"
      :role-type="5"
      @searchList="searchList"
    ></addTeacherModel>
    <footer-tab
      v-if="showTab"
      :multiple-selection="multipleSelection"
      :message="tabStr"
      @delete="deleteTeacher"
      @hidden="hidden"
    />
    <el-dialog title="批量导入" :visible.sync="dialogVisible" width="652px">
      <div>
        <div>
          <div>
            文件上传：
            <el-link
              type="primary"
              :href="
                'https://static.wtjy.com/analy/template/%E6%95%99%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls?time=' +
                time
              "
              >教师信息模版下载<i class="el-icon-download"></i
            ></el-link>
          </div>
        </div>
        <el-upload
          ref="upload"
          class="upload-demo"
          name="file"
          drag
          :action="uploadAvatarURL"
          :headers="uploadAvatarHeaders"
          :file-list="fileList"
          :on-success="handleOnSuccess"
          :auto-upload="false"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">请将文件拖放到此处或点击上传</div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  schoolteacherRolePage,
  schoolteacherRoleDel,
} from "@/core/api/newOther/authority";
import addTeacherModel from "./component/addTeacherModel.vue";
import footerTab from "../component/footerTab.vue";
import { getStore } from "@/core/util/store";
export default {
  name: "SchoolLeadership",
  components: {
    addTeacherModel,
    footerTab,
  },
  data() {
    return {
      uploadAvatarURL: "/cms/schoolstudent/import",
      uploadAvatarHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "schoolId" }),
      },
      fileList: [],
      dialogVisible: false,
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      teacherList: [],
      name: "",
      showTab: false,
      listLoading: false,
      tabStr: "此操作将移除教师当前权限，是否继续？",
      time: new Date().getTime(),
    };
  },
  created() {
    this.schoolteacherRolePage();
  },
  methods: {
    showImport() {
      this.dialogVisible = true;
    },
    handleOnSuccess(response) {
      if (response.code === 1) {
        this.$message.error("导入失败");
        window.open(response.msg, "_blank");
      } else {
        this.$message.success("导入成功");
        this.getData();
      }
      this.dialogVisible = false;

      this.submitLoading = false;
    },
    del(val) {
      this.$confirm(this.tabStr, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteTeacher(val);
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    hidden() {
      this.showTab = false;
      // this.tabStr = "";
      this.multipleSelection = [];
    },
    deleteTeacher(val) {
      let data = {
        type: 5,
      };
      if (val) {
        data.teacherId = val.id;
      }
      if (this.showTab) {
        let ids = this.multipleSelection.map((item) => item.id);
        data.teacherId = ids.join(",");
      }
      schoolteacherRoleDel(data).then(() => {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
        this.searchList();
        this.hidden();
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.teacherList = [];
      this.schoolteacherRolePage();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.teacherList = [];
      this.schoolteacherRolePage();
      // console.log(`当前页: ${val}`);
    },
    addTeacher() {
      this.$refs.addTeacherModel.showModel(5);
    },
    searchList() {
      this.pageIndex = 1;

      this.schoolteacherRolePage();
    },
    // 分页获取老师列表
    schoolteacherRolePage() {
      let data = {
        roleType: 5,
        current: this.pageIndex,
        size: this.pageSize,
        name: this.name,
      };
      this.listLoading = true;
      schoolteacherRolePage(data)
        .then((res) => {
          this.teacherList = res.data.data.records;
          this.total = res.data.data.total;
          this.listLoading = false;
          // console.log(res);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // schoolteacherlistv2() {},
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.showTab = true;
      } else {
        this.showTab = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.SchoolLeadership {
  // padding: 24px;
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .el-input {
      width: 192px;
      i {
        cursor: pointer;
      }
    }
  }
}
</style>
