<template>
  <div class="addTeacherModel">
    <el-dialog
      title="批量设置该权限教师"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div class="search-box">
        <div class="search-item">
          学段：
          <el-select
            v-model="modelSearch.level"
            placeholder="请选择学段"
            @change="levelChange"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          班级：
          <el-select
            v-model="modelSearch.classNum"
            placeholder="请选择班级"
            @change="selectChange"
          >
            <el-option
              v-for="item in classOptions"
              :key="item.id"
              :label="item.classNum"
              :value="item.classNum"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item ts-item">
          <!-- yearHignOptions yearMiddleOptions -->
          入学年份：
          <el-select
            v-if="modelSearch.level == 2"
            v-model="modelSearch.year"
            placeholder="请选择入学年份"
            @change="selectChange"
          >
            <el-option
              v-for="item in yearMiddleOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            v-else
            v-model="modelSearch.year"
            placeholder="请选择入学年份"
            @change="yearChange"
          >
            <el-option
              v-for="item in yearHignOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="search-item">
          科目：
          <el-select
            v-model="modelSearch.subjectId"
            placeholder="请选择科目"
            @change="selectChange"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          搜索：
          <el-input
            v-model="modelSearch.name"
            class="search-input"
            placeholder="请输入搜索内容"
            @keyup.enter.native="schoolteacherlistv2Copy"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="schoolteacherlistv2Copy"
            >
            </i
          ></el-input>
        </div>
      </div>
      <div class="teacher-box">
        <div>
          <div class="teacher-header">
            <el-checkbox v-model="checkedAll" @change="changeChecked(1)">
              全选
            </el-checkbox>
            <el-checkbox v-model="checkedFalse" @change="changeChecked(2)">
              反选
            </el-checkbox>
          </div>
          <div class="teacher-list-box edit-scroll-style">
            <el-checkbox-group v-model="checkTeacherList">
              <el-checkbox
                v-for="(v, i) in teacherList"
                :key="i"
                :label="v.teacherId"
                :disabled="getDisTeacher(v)"
              >
                {{ v.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div>
          <div class="teacher-header right-teacher-header">
            <el-button type="text" @click="clear()">清空</el-button>
          </div>
          <div class="teacher-list-box edit-scroll-style">
            <template v-for="(v, i) in teacherList">
              <div
                v-if="
                  checkTeacherList.filter((item) => item == v.teacherId)
                    .length > 0
                "
                :key="i"
                class="teacher-list"
              >
                {{ v.name }}
                <i class="el-icon-close" @click="clear(v)"></i>
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="save">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { filterSubject } from "@/core/util/util";
import { levelOptions, teacherRole } from "@/core/util/constdata";
import {
  schoolteacherRolelist,
  schoolteacherRoleSave,
} from "@/core/api/newOther/authority";
import { schoolteacherlistv2Copy } from "@/core/api/school/schoolteacher";
import {
  getGradeList,
  getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
export default {
  name: "AddTeacherModel",
  props: {
    roleType: Number,
  },
  data() {
    return {
      checkedFalse: false,
      checkedAll: false,
      dialogVisible: false,
      btnLoading: false,
      teacherList: [],
      checkTeacherList: [],
      levelOptions: levelOptions(),
      teacherRole: teacherRole,
      modelSearch: {
        level: "",
        subjectId: "",
        year: "",
        roleId: "",
        classNum: "",
        name: "",
        // roleType: "",
      },
      // roleType: 1,
      classOptions: [],
      yearHignOptions: [],
      yearMiddleOptions: [],
    };
  },
  created() {
    this.subjectOptions = filterSubject(this.$route.query.levelId);
    this.schoolteacherRolelist();
    this.getSchoolYearList();
  },
  methods: {
    schoolteacherRoleSave(data) {
      // let data = {};
      this.btnLoading = true;
      schoolteacherRoleSave(data)
        .then(() => {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.$emit("searchList");
          // 更新本地数据
          this.schoolteacherRolelist();
          this.btnLoading = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    showModel() {
      // 当前老师角色
      // this.roleType = val;
      this.dialogVisible = true;
      this.checkedFalse = false;
      this.checkedAll = false;
      this.checkTeacherList = [];
      this.schoolteacherlistv2Copy();
    },
    // 获取所有年级列表
    getSchoolYearList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          grades.forEach((item) => {
            if (item.level == 2) {
              this.yearMiddleOptions.push(item.year);
            } else {
              this.yearHignOptions.push(item.year);
            }
          });
          this.yearHignOptions = [...new Set(this.yearHignOptions)];
          this.yearMiddleOptions = [...new Set(this.yearMiddleOptions)];
        }
      });
    },
    // 获取班级列表
    changeClassOptions() {
      if (this.modelSearch.level && this.modelSearch.year) {
        let query = {
          level: this.modelSearch.level,
          year: this.modelSearch.year,
        };
        this.classOptions = [];
        getClassListByLevelAndYear(query).then((response) => {
          this.modelSearch.classNum = "";
          this.classOptions = response.data.data;
        });
      }
    },
    //全选 反选
    changeChecked(type) {
      // type 等于1就是全选  2就是反选
      if (type == 1) {
        this.checkedFalse = false;
        this.checkTeacherList = [];
        if (this.checkedAll) {
          // console.log(this.roleTeacherList);
          // console.log(this.teacherList);
          this.teacherList.forEach((index) => {
            let roleList = this.roleTeacherList.filter(
              (item) => item.userId == index.userId
            );

            if (roleList.length == 0) {
              this.checkTeacherList.push(index.teacherId);
            }
          });
        }
      } else {
        this.checkedAll = false;
        let array = [];
        this.teacherList.forEach((index) => {
          if (
            this.checkTeacherList.filter((item) => item == index.teacherId)
              .length == 0
          ) {
            let roleList = this.roleTeacherList.filter(
              (item) => item.userId == index.userId
            );
            if (roleList.length == 0) {
              array.push(index.teacherId);
            }
          }
          // array.push(index.teacherId);
        });
        this.checkTeacherList = array;
      }
    },
    getDisTeacher(v) {
      let type = false;
      this.roleTeacherList.forEach((item) => {
        if (item.userId == v.userId) {
          type = true;
        }
      });
      return type;
    },
    // 删除老师
    clear(v) {
      if (!v) {
        this.checkTeacherList = [];
      } else {
        this.checkTeacherList = this.checkTeacherList.filter(
          (item) => item != v.id
        );
      }
    },
    // 不分页,获取老师当前权限列表
    schoolteacherRolelist() {
      let data = {
        roleType: this.roleType,
      };
      schoolteacherRolelist(data).then((res) => {
        this.roleTeacherList = res.data.data;
        // console.log(res);
      });
    },
    // 不分页直接获取老师列表
    schoolteacherlistv2Copy() {
      let data = this.modelSearch;
      schoolteacherlistv2Copy(data).then((res) => {
        // console.log(res);
        this.teacherList = res.data.data;
      });
    },
    save() {
      let schoolTeacherRoleList = [];
      this.checkTeacherList.forEach((item) => {
        schoolTeacherRoleList.push({
          teacherId: item,
          type: this.roleType,
        });
      });
      let data = {
        schoolTeacherRoleList: schoolTeacherRoleList,
      };
      this.schoolteacherRoleSave(data);
      // console.log(teacherList);
    },
    selectChange() {
      this.schoolteacherlistv2Copy();
    },
    yearChange() {
      this.changeClassOptions();
      this.schoolteacherlistv2Copy();
    },
    levelChange() {
      this.subjectOptions = filterSubject(this.modelSearch.level);
      let filterList = this.subjectOptions.filter(
        (item) => this.modelSearch.subjectId == item.subjectId
      );
      if (filterList.length == 0) {
        this.modelSearch.subjectId = "";
      }
      this.schoolteacherlistv2Copy();
    },
  },
};
</script>
<style lang="scss" scoped>
.addTeacherModel {
  .teacher-box {
    display: flex;
    .teacher-list-box {
      padding: 18px 24px;
      height: 338px;
      overflow-y: scroll;
      .teacher-list {
        color: $primary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        i {
          cursor: pointer;
          font-size: 16px;
          color: #dee1e7;
        }
      }
      .el-checkbox {
        display: block;
        margin-bottom: 18px;
      }
    }
    .right-teacher-header {
      display: flex;
      justify-content: flex-end;
    }
    .teacher-header {
      padding: 9px 24px;
      background: #f5f7fa;
      width: 350px;
      .el-checkbox {
        margin-bottom: 0;
      }
    }
    > div {
      border: 1px solid #dee1e7;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  .search-box {
    display: flex;
    flex-wrap: wrap;
    .search-item.ts-item {
      justify-content: flex-end;
    }
    .search-input {
      i {
        cursor: pointer;
      }
    }
    .search-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;
      width: 250.5px;
      .el-input {
        width: 166px;
      }
      .el-select {
        width: 166px;
        // margin-left: 8px;
        // margin-right: 24px;
      }
    }
  }
}
</style>
