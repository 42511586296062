import request from "@/core/services/axios";

// 校领导-年级组长-学科组长-班主任-任课老师等的管理列表(带分页)
export function schoolteacherRolePage(params) {
  return request({
    url: `/cms/schoolteacher/role/page`,
    method: "get",
    params: params,
  });
}

// 校领导-年级组长-学科组长-班主任-任课老师等的管理列表(不分页)
export function schoolteacherRolelist(params) {
  return request({
    url: `/cms/schoolteacher/role/list`,
    method: "get",
    params: params,
  });
}

//校领导-年级组长-学科组长-班主 任-任课老师等的添加接口（适用批量添加）
export function schoolteacherRoleSave(data) {
  return request({
    url: `/cms/schoolteacher/role/save`,
    method: "post",
    data: data,
  });
}

//校领导-年级组长-学科组长-班主 任-任课老师等的删除接口
export function schoolteacherRoleDel(data) {
  return request({
    url: `/cms/schoolteacher/role/del`,
    method: "delete",
    data: data,
  });
}
